
















































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { blockUserAlert, approveUserAlert, favoriteUserAlert, unFavoriteUserAlert,
  blockCancelUserAlert,
  blockTransferAlert,
  cancelAllMeetingAlert,
  cancelMeetingAlert,
  blockCancelDomainUsersAlert,
  unblockCancelDomainUsersAlert,
} from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import { ValidationObserver } from "vee-validate";
import msgConstants from "@/constants/_message";
import BlockLocationsModal from "@/components/blockLocationsModal/BlockLocationsModal.vue";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    BlockLocationsModal
  }
})
export default class MedicalRepList extends Vue {
  @Action public getUsers: any;
  @Action public deleteUserById: any;
  @Action private updateUser: any;
  @Getter public users: any;
  @Action public getUserCount: any;
  @Action public performRepActivities: any;
  @Action public blockAndCancel: any;
  @Action public blockAndTransfer: any;
  @Action private getAllUsers: any;
  @Getter public userCount: any;
  @Getter private allUsers: any;
  @Action public setRepName: any;
  @Action public getHealthCareDomains: any;
  @Getter public healthcareDomains: any;
  @Getter private role!: any;
  @Action public updateFavoriteuser: any;
  @Action public downloadSignedHipaaForm: any;
  @Action public getTenantLocation: any;
  @Action public getPracticeLocationByUser: any;
  @Getter public currentUser: any;
  @Action public downloadVaccinationCertification: any;
  @Getter public rolePerm: any;
  perm = _permissions;

  $refs!: {
    transferForm: InstanceType<typeof ValidationObserver>;
    appModalRef: HTMLFormElement;
    blockLocationModal: HTMLElement;
  };
  searchText = "";
  isMedicalRep = false;
  showBlockedReps = false;
  userFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      is_medical_rep: true,
      status: { nin: [0,9] },
    },
    include: [
          {
            relation: "userOrganizationTypes",
            scope: {
              fields: [
                "organizationTypeId",
                "userId"
              ],
              include: [
                {
                  relation: "organization_type",
                  scope: {
                    fields: [
                      "id",
                      "name"
                    ]
                  }
                }
              ]
            }
          }
        ],
    order: ["first_name ASC"],
  };
  pageOptions = [10, 20];
  currentPage = 1;
  userWhere: any = {
    userTypesId: { neq: 1 },
    status: { neq: 0 },
    is_medical_rep: true,
  };
  transferQuery: any =  {};
  isSubmitted = false;
  selectedDomain: any = '';
  selectedRep: any = {};

  beforeMount() {
    this.getUserList();
    this.getHealthCareDomains();
  }

  getUserList() {
    this.getUsers(this.userFilter);
    this.getUserCount(this.userWhere);
    this.transferQuery = {};
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.userFilter.skip = 0;
      this.userFilter.where.and = [
        {email: {ilike: this.selectedDomain !== null  && this.selectedDomain !== ""? `%@${this.selectedDomain}` : `%`}},
        {
          or: [
          { first_name: { ilike: `%${this.searchText}%` } },
          { last_name: { ilike: `%${this.searchText}%` } },
          { location: { ilike: `%${this.searchText}%` } },
          ]
        }
      ];
      this.userWhere.and = [
        {email: {ilike: this.selectedDomain !== null && this.selectedDomain !== ""? `%@${this.selectedDomain}` : `%`}},
        {
          or: [
          { first_name: { ilike: `%${this.searchText}%` } },
          { last_name: { ilike: `%${this.searchText}%` } },
          { location: { ilike: `%${this.searchText}%` } },
          ]
        }
      ];
      this.getUserList();
    }
  }

  repOrganizationTypes(orgTypes: any) {
    let repOrgType: any = [];
    orgTypes.forEach((orgType: any) => {
      repOrgType.push(orgType.organization_type.name);
    });
    return repOrgType.toString();
  }

  pageSizeChanged() {
    this.userFilter.skip = 0;
    this.getUserList();
  }

  onPageChange(currentPage: any) {
    this.userFilter.skip = (currentPage - 1) * this.userFilter.limit;
    this.getUserList();
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addUser(): void {
    this.$router.push({ name: "user.create" });
  }

  editUser(id: number): void {
    this.$router.push({ path: `/user/update/${id}` });
  }

  blockUser(id: number, tenantId: number): void {
    alert.methods.conFirmAlert(
      blockUserAlert,
      () => {
        this.updateUser({
          data: { id: id, status: 2, tenantId },
          callback: this.getUserList,
        });
      },
      () => {}
    );
  }

  approveUser(id: number, tenantId: number): void {
    alert.methods.conFirmAlert(
      approveUserAlert,
      () => {
        this.updateUser({
          data: { id: id, status: 1, tenantId },
          callback: this.getUserList,
        });
      },
      () => {}
    );
  }

  getImgUrl(rep: any) {
    var images = require.context("@/assets/icons/", false, /\.png$/);
    return images(
      "./" + (rep.favorite ? "favorite-enabled" : "favorite-disabled") + ".png"
    );
  }

  setFavorite(rep: any): void {
    const alertMessage = rep.favorite ? unFavoriteUserAlert : favoriteUserAlert;
    alert.methods.conFirmAlert(
      alertMessage,
      () => {
        this.updateFavoriteuser({
          data: { params: {id: rep.id}, body: {favorite: !rep.favorite} },
          callback: this.getUserList,
        });
      },
      () => {}
    );
  }

  backToUserManagement(): void {
    this.$router.push({ name: "user-management.list" }).catch();
  }

  /**
   * It handles the user block and cancel the appointments of that user based on action
   */
  onBlockOrCancelUser(id: number, action: string, tenantId: number): void {
    alert.methods.conFirmAlert(
      action === 'block' ? blockCancelUserAlert : cancelAllMeetingAlert,
      () => {
        const requestQuery: any = {
          fromUser: id,
          tenant_id: tenantId,
          meetingStatus: 'cancel',
          msg: action === 'block' ?  msgConstants.BLOCK_CANCEL_USER_SUCCESS : msgConstants.CANCEL_ALL_MEETINGS_SUCCESS
        }
        if (action === 'block') {
          requestQuery.userStatus = 'block';
        }
        this.performRepActivities({requestData: requestQuery, cb: this.getUserList});
      },
      () => { this.transferQuery = {} }
    );
  }

  onBlockAndTransfer(id: number, tenantId: number): void {
    alert.methods.conFirmAlert(
      blockTransferAlert,
      () => {
        this.transferQuery.userStatus = 'block';
        this.openTransferModal(id, tenantId);
      },
      () => { this.transferQuery = {} }
    );
  }

  openTransferModal(id: number | null, tenantId: number) {
    this.getAllUsers({where: {
      and: [
        {status: {nin: [0, 2]}},
        { userTypesId: { neq: 1 }, is_medical_rep: true },
        {id: {neq: id}}
      ]
    }, order: ["first_name ASC"]});
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
    this.transferQuery.fromUser = id;
    this.transferQuery.tenant_id = tenantId;
  }

  onFormSubmit() {
    this.isSubmitted = true;
    this.$refs.transferForm.validate().then((success: any) => {
      if (!success) {
        return
      } else {
        const data = {...this.transferQuery,
          meetingStatus: 'transfer',
          msg: msgConstants.MEETING_TRANSFER_SUCCESS
        };
        if ('userStatus' in this.transferQuery) {
          data.msg = msgConstants.BLOCK_TRANSFER_SUCCESS;
        }
        this.performRepActivities({requestData: data, cb: () => {
          this.closeModal();
          if ('userStatus' in data) {
            this.getUserList();
          }
        }});
      }
    })
  }

  closeModal() {
    this.transferQuery = { toUser: null, fromUser: null };
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
    this.transferQuery = {};
  }

  onCancel() {
     alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.closeModal();
        this.transferQuery = {};
      },
      () => { }
    );
  }
  
  setDomain(value: any): void {
    this.selectedDomain = value;
    this.onChangeFilterInput();
  }

  messageUser(user: any): void{
    this.setRepName(`${user.first_name} ${user.last_name}`);
    this.$router.push({path: '/messages'});
  }

  blockDomainUsers(): void {
    alert.methods.conFirmAlert(
      blockCancelDomainUsersAlert,
      () => {
        let value = {
          searchText: this.searchText,
          healthcareDomain: this.selectedDomain,
          userStatus: 'block',
          meetingStatus: 'cancel',
          showBlockedReps: this.showBlockedReps,
          msg: `Users of ${this.selectedDomain} are blocked and their appointments are cancelled successfully`
        };
        this.performRepActivities({requestData: value, cb: this.getUserList });
      },
      () => { }
    );
  }

  unBlockDomainUsers(): void {
    alert.methods.conFirmAlert(
      unblockCancelDomainUsersAlert,
      () => {
        let value = {
          searchText: this.searchText,
          healthcareDomain: this.selectedDomain,
          userStatus: 'unblock',
          showBlockedReps: this.showBlockedReps,
          msg: `Users of ${this.selectedDomain} are unblocked successfully`
        };
        this.performRepActivities({requestData: value, cb: this.getUserList });
      },
      () => { }
    );
  }

  downloadHipaaForm(hipaaFilrUrl: any) {
    this.downloadSignedHipaaForm(hipaaFilrUrl);
  }

  downloadVaccinationCertificate(certificateUrl: any) {
    this.downloadVaccinationCertification(certificateUrl);
  }

  onBlockLocations(rep: any): void {
    this.getTenantLocation({
      fields: { id: true, name: true },
      where: { status: { eq: true }, tenantId: { eq: rep.tenantId } },
      order: ["name ASC"],
    });
    this.getPracticeLocationByUser({
      data: {
        offset: 0,
        limit: 1000,
        skip: 0,
        where: {
          userId: rep.id,
          tenant_id: rep.tenantId,
          status: false,
        },
        fields: {
          id: true,
          tenantId: true,
          userId: true,
          tenantLocationId: true,
          status: true,
        },
        include: [
          {
            relation: "tenantLocation",
            scope: {
              offset: 0,
              limit: 100,
              fields: { id: true, name: true },
              include: [],
            },
          },
        ],
      },
      callBack: () => {
        setTimeout(() => {
          const blockLocationModal = this.$refs.blockLocationModal as HTMLFormElement;
          blockLocationModal.show();
          this.selectedRep = rep;
        }, 500);
      }
    });
  }

  changeStatus(e){
    delete this.userFilter.where.show_blocked_reps;
    delete this.userWhere.show_blocked_reps;
    this.showBlockedReps = false;
    if(e){
      this.showBlockedReps = true;
      this.userFilter.where.show_blocked_reps = true;
      this.userWhere.show_blocked_reps = true;
    }
    this.getUserList();
  }

  editRepUser(userId:number): void{
    this.$router.push({ path: `/rep-user/update/${userId}` });
  }
}
