

























































































import alert from '@/mixins/alert';
import { cancelMeetingAlert } from '@/utils/notificationContent';
import { ValidationObserver } from 'vee-validate';
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import _ from 'lodash';
import constants from "@/constants/_message";
import { isNotEmpty } from "@/utils/object";

@Component
export default class MedicalRepForm extends Vue {
    @Action public initNewMedicalRep: any;
    @Action public getMedicalRepById: any;
    @Action public getOrganizationTypes: any;
    @Action public startLoading: any;
    @Action private updateMedicalRep: any;
    @Action public endLoading: any;
    @Getter public currentUser: any;
    @Getter public organizationTypes: any;
    @Getter public medicalrep: any;


    isSubmitted = false;
    repOrganizationTypes: any = [];
    $refs!: {
        appModalRef: HTMLFormElement;
        medicalRepForm: InstanceType<typeof ValidationObserver>;
    }

    beforeMount() {
        this.initNewMedicalRep();
        const userId = this.$route.params.id;
        this.getOrganizationTypes({ where: { status: true }, fields: { id: true, name: true } });
        userId && this.getMedicalRepById({
            id: userId, filter: {
                include: [
                    {
                        relation: "userOrganizationTypes",
                        scope: {
                            fields: [
                                "organizationTypeId",
                                "userId"
                            ],
                            include: [
                                {
                                    relation: "organization_type",
                                    scope: {
                                        fields: [
                                            "id",
                                            "name"
                                        ]
                                    }
                                },
                            ]
                        }
                    }
                ]
            }
        });
        this.getUserDetails();
    }

    getUserDetails() {
        setTimeout(() => {
            this.startLoading();
        }, 600);
        setTimeout(() => {
            if (this.medicalrep.userOrganizationTypes) {
                this.medicalrep.userOrganizationTypes.forEach((userOrganizationType: any) => {
                    this.repOrganizationTypes.push(userOrganizationType.organization_type);
                });
                this.setOrganizationTypes(this.repOrganizationTypes);
            }
            this.endLoading();
        }, 2000);
    }

    setOrganizationTypes(values: any) {
        this.repOrganizationTypes = values;
        const groupedById = _.groupBy(values, value => value.id);
        const result = Object.keys(groupedById);
        this.medicalrep.organization_types = result;
    }

    onFormSubmit(): void {
        this.isSubmitted = true;
        this.$refs.medicalRepForm.validate().then((success: any) => {
            if (!success) {
                alert.methods.appToaster({
                    message: constants.FORM_ERROR,
                    type: "ERROR"
                });
                const firstErrorFocused: HTMLFormElement = Object(
                    document.querySelector(".error:first-of-type")
                );
                if (
                    isNotEmpty(firstErrorFocused) &&
                    !firstErrorFocused.classList.contains("IZ-select")
                ) {
                    firstErrorFocused.querySelector("input");
                    firstErrorFocused.focus();
                }
                const errors = Object.entries(this.$refs.medicalRepForm.errors)
                    .map(([key, value]) => ({ key, value }))
                    .filter(error => error["value"].length);
                if (errors.length) {
                    this.$refs.medicalRepForm.refs[errors[0]["key"]].$el.scrollIntoView({
                        behavior: "smooth",
                        block: "center"
                    });
                }
                return;
            } else {
                this.checkNullFeilds();
                const { id, first_name, last_name, email, phone, address, city, state, pharma_company, products, zipcode, 
                    territory, organization_types, comment } = this.medicalrep;
                this.updateMedicalRep({
                    data: {
                        id,
                        first_name,
                        last_name,
                        email,
                        phone,
                        address,
                        pharma_company,
                        products,
                        zipcode,
                        territory,
                        city,
                        state,
                        organization_types,
                        comment
                    },
                    callback: () => {
                        this.$router.push({ name: 'rep-users.index' }).catch();
                    }
                });
            }
        });
    }

    checkNullFeilds() {
        if (this.medicalrep.address === null) {
            this.medicalrep.address = "";
        }
        if (this.medicalrep.zipcode === null) {
            this.medicalrep.zipcode = "";
        }
        if (this.medicalrep.territory === null) {
            this.medicalrep.territory = "";
        }
        if (this.medicalrep.products === null) {
            this.medicalrep.products = "";
        }
        if (this.medicalrep.phone === null) {
            this.medicalrep.phone = "";
        }
        if (this.medicalrep.city === null) {
            this.medicalrep.city = "";
        }
        if (this.medicalrep.state === null) {
            this.medicalrep.state = "";
        }
    }

    onCancel(): void {
        alert.methods.conFirmAlert(
            cancelMeetingAlert,
            () => {
                this.$router.push({ name: 'rep-users.index' }).catch();
            },
            () => { }
        );
    }

}
