
































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import alert from "@/mixins/alert";
import { cancelMeetingAlert } from "@/utils/notificationContent";
import constants from "@/constants/_message";
import { ValidationObserver } from "vee-validate";
import { isNotEmpty } from "@/utils/object";
import LetterIcon from "@/components/letterIcon/LetterIcon.vue";
import _ from "lodash";
import MultipleSelect from '@/components/formComponents/MultipleSelect/index.vue'

@Component({
  components: {
    LetterIcon,
    MultipleSelect
  },
})
export default class BlockLocationsModal extends Vue {
  @Prop({ default: "xl" }) public size!: any;
  @Prop() public selectedRep: any;

  @Action public addOrUpdatePracticeLocation: any;
  @Getter public currentUser: any;
  @Getter public locations: any;
  @Getter public practiceLocationsByUser: any;

  isSubmitted = false;
  $refs!: {
    practiceLocationForm: InstanceType<typeof ValidationObserver>;
  };
  selectedLocations: any = [];
  filteredLocations: any = [];

  beforeMount() {
    }

  setSelectedLocations(values: any) {
    this.selectedLocations = values;
  }

  @Watch('practiceLocationsByUser')
  getSelectedLocation() {
    this.selectedLocations = [];
    this.practiceLocationsByUser.forEach((element: any) => {
      this.selectedLocations.push(element.tenantLocation);
    });
  }

  @Watch('locations')
  locationsChanged() {
    setTimeout(() => {
      const ids = this.selectedLocations.map(o => o.id);
      const filtered = this.locations.filter(({id}) => !ids.includes(id));
      this.filteredLocations = filtered;
    }, 300);
  }

  onFormSubmit(): void {
    this.isSubmitted = true;
    this.$refs.practiceLocationForm.validate().then((success: any) => {
      if (!success) {
        alert.methods.appToaster({
          message: constants.FORM_ERROR,
          type: "ERROR",
        });
        const firstErrorFocused: HTMLFormElement = Object(
          document.querySelector(".error:first-of-type")
        );
        if (
          isNotEmpty(firstErrorFocused) &&
          !firstErrorFocused.classList.contains("IZ-select")
        ) {
          firstErrorFocused.querySelector("input");
          firstErrorFocused.focus();
        }

        const errors = Object.entries(this.$refs.practiceLocationForm.errors)
          .map(([key, value]) => ({ key, value }))
          .filter((error) => error["value"].length);
        if (errors.length) {
          this.$refs.practiceLocationForm.refs[errors[0]["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
        return;
      } else {
        const groupedById = _.groupBy(this.selectedLocations, (value) => value.id);
        const result = Object.keys(groupedById).map(Number);
        const oldLocs = this.practiceLocationsByUser.filter((l:any)=> result.includes(l.tenantLocationId));
        const oldLocIds = oldLocs.length && oldLocs.map((v:any)=>+v.tenantLocationId) || []; 
        const newLocIds = result.filter(r => !oldLocIds.includes(+r));
        const data = {
          oldLocationIds: oldLocIds,
          newLocationIds: newLocIds,
          location_ids: result,
          status: false,
          tenant_id: this.selectedRep.tenantId,
          userId: this.selectedRep.id,
        };
        this.addOrUpdatePracticeLocation(data);
        this.hide();
      }
    });
  }

  onCancel() {
    alert.methods.conFirmAlert(
      cancelMeetingAlert,
      () => {
        this.hide();
      },
      () => {}
    );
  }

  show(): void {
    this.$bvModal.show("practice-location-modal");
  }

  hide(): void {
    this.$bvModal.hide("practice-location-modal");
  }
  
  getName(): string {
    return this.selectedRep.first_name 
      ? `${this.selectedRep.first_name.charAt(0).toUpperCase() + this.selectedRep.first_name.slice(1) + ' ' 
      + this.selectedRep.last_name.charAt(0).toUpperCase() + this.selectedRep.last_name.slice(1) }`
      : '';
  }
}
