






























































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LetterIcon from "../../components/letterIcon/LetterIcon.vue";
import alert from "@/mixins/alert";
import { deleteUserAlert, changeUserAlert, resetPasswordAlert } from "@/utils/notificationContent";
import AppModal from "@/components/baseComponents/Modal/Index.vue";
import constants from "@/constants/_message";
import _permissions from "@/constants/_permissions";

@Component({
  components: {
    AppModal,
    LetterIcon,
  },
})
export default class UserList extends Vue {
  @Action public getUsers: any;
  @Action public deleteUserById: any;
  @Action public getUserCount: any;
  @Getter public users: any;
  @Getter public userCount: any;
  @Action public deleteAdminandSubscribers: any;
  @Action public switchUser: any;
  @Action public resetUserPassword: any;
  @Getter public rolePerm: any;
  perm = _permissions;
  searchText = "";
  usersToDisplay = [];
  isMedicalRep = false;
  userFilter: any = {
    limit: 20,
    skip: 0,
    where: {
      status: 1,
      and: [
        {userTypesId: { eq: 2 }},
        {is_medical_rep: false},
      ],
    },
    order: ["first_name ASC"],
  };
  pageOptions = [10, 20];
  currentPage = 1;
  userWhere: any = {
    status: 1,  
    and: [
      {userTypesId: { eq: 2 }},
      {is_medical_rep: false}
    ],  
  };
  ref: any = "appModalRef";
  generatedPassword = '';
  newPassword = '';
  userId = 0;

  beforeMount() {
    this.getUserList();
  }

  getUserList() {
    this.getUsers(this.userFilter);
    this.getUserCount(this.userWhere);
  }

  pageSizeChanged() {
    this.userFilter.skip = 0;
    this.getUserList();
  }

  onPageChange(currentPage: any) {
    this.userFilter.skip = (currentPage -1) * this.userFilter.limit;
    this.getUserList();
  }

  onChangeFilterInput() {
    if (this.searchText.length == 0 || this.searchText.length >= 3) {
      this.userFilter.where.or = [
        { first_name: { ilike: `%${this.searchText}%` } },
        { last_name: { ilike: `%${this.searchText}%` } },
        { location: { ilike: `%${this.searchText}%` } },
      ];
      this.userWhere.or = [
        { first_name: { ilike: `%${this.searchText}%` } },
        { last_name: { ilike: `%${this.searchText}%` } },
        { location: { ilike: `%${this.searchText}%` } },
      ];
      this.userFilter.skip = 0;
      this.getUserList();
    }
  }

  clearSearch() {
    this.searchText = "";
    this.onChangeFilterInput();
  }

  addUser(): void {
    this.$router.push({ name: "user.create" });
  }

  editUser(id: number): void {
    this.$router.push({ path: `/user/update/${id}` });
  }

  backToUserManagement(): void {
    this.$router.push({ name: "user-management.list" }).catch();
  }

  deleteUser(id: any): void {
    alert.methods.conFirmAlert(
      deleteUserAlert,
      () => {
        this.deleteAdminandSubscribers({ data: { id: id, status: 2 }, callback: this.getUserList});
      },
      () => {}
    );
  }

  changeUser(id:number): void{
    alert.methods.conFirmAlert(
      changeUserAlert,
      () => {
        this.switchUser({ data: { id: id, userTypesId:6}, callback: this.getUserList});
      },
      () => {}
    );
  }

  openResetPasswordMoadl(id: any) {
    this.generatedPassword = '';
    this.newPassword = '';
    this.userId = id;
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.show();
  }

  hideModal() {
    this.generatedPassword = '';
    this.newPassword = '';
    this.userId = 0;
    const appModal = this.$refs.appModalRef as HTMLFormElement;
    appModal.hide();
  }

  generatePassword() {
    let length = 13,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$&",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    this.generatedPassword = retVal;
    this.newPassword = retVal;
  }

  resetUsersPassword() {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#_$=%?\^&\*\+\-])(?=.{8,})");/* eslint-disable-line */
    if(this.generatedPassword === this.newPassword) {
      this.resetPassword();
    } else if(strongRegex.test(this.newPassword)) {
      this.resetPassword();
    } else {
      alert.methods.appToaster({ message: constants.PASSWORD_PATTERN_ERROR, type: "ERROR" });
    }
  }

  resetPassword() {
    alert.methods.conFirmAlert(
      resetPasswordAlert, 
      () => {
        let data = {
          id: this.userId,
          new_password: this.newPassword,
          is_auto_gen: this.generatedPassword === this.newPassword
        };
        this.resetUserPassword({ data: data, callback: this.hideModal });
      }, 
      () => {

      }
    );
  }
}
